:root {
  --bg-light: #d7d3d3;
  --bg-dark: black;
  --bg-item-dark: #1c1b1b;
  --bg-item-light: #505050;
  --text-light: var(--bg-dark);
  --text-dark: var(--bg-light);
  --transition: 0.1s;
  --padding: 15vw;
}

* {
  margin: 0;
  box-sizing: border-box;
  transition: all var(--transition);
}

/* ----- Pages Container ----- */
.main-container {
  height: 100vh;
  background-color: var(--bg-light);
  color: var(--text-light);
}
.main-container.dark {
  background-color: var(--bg-dark);
  color: var(--text-dark);
}

/* ----- NavBar ----- */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding-left: var(--padding);
  padding-right: var(--padding);
  height: 60px;
  width: 100%;
}

/* ----- Logo -----*/
.logo-container {
  display: flex;
  /* margin-left: calc(5px + 0.3vw); */
}
.logo {
  width: 40px;
}
.navbar h1 {
  margin-left: 5px;
}

/* ----- Links ----- */
.navbar-buttons {
  display: flex;
}

.theme-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(5px + 0.3vw);
}
.theme-btn.modal {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-container p {
  font-size: 25px;
  cursor: default;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(80px + 4vw);
}

.btn-container:hover {
  color: white;
  transition: 0s;
}

.btn-container.modal {
  padding-left: 10px;
  width: 100%;
  height: 50px;
  justify-content: flex-start;
}
.btn-container.modal:hover {
  background-color: var(--bg-item-dark);
  color: white;
}
.btn-container.modal.dark:hover {
  background-color: black;
  color: white;
}
/* ----- Sandwich Icon ----- */
.menu-icon-container {
  background-color: transparent;
  border: none;
}
.menu-icon {
  aspect-ratio: 1;
  width: 50px;
  margin-right: 10px;
}

.line {
  border-radius: 2px;
  margin-left: 2.5px;
  width: 90%;
  height: 10%;
  background-color: black;
}

.line.dark {
  background-color: var(--bg-light);
}

/* ----- Components ----- */
/* ----- Beat List ----- */
.beat-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  width: 49.5vw;
  max-height: 680px;
  margin: 0.5vw;
  overflow: scroll;
}

/* ----- Beat Item ----- */
.beat-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* aspect-ratio: 1; */
  min-height: 80px;
  width: 350px;
  margin: 0.5vw;
  /* border-radius: 50%; */
  border: none;
  background-color: var(--bg-item-light);
  font-weight: bold;
  font-size: 25px;
}
.beat-item:hover {
  width: 506px;
  /* font-size: 55px; */
}

/* ----- Beat Details ----- */
.beat-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 49.5vw;
  height: 680px;
  margin: 0.5vw;
}

/* ----- Menu Modal ----- */

.modal-container {
  position: absolute;
  width: 200px;
  height: 300px;
  right: calc(70px + var(--padding));
  top: 0;
  overflow: hidden;
}

.menu-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 0;
  width: 200px;
  height: 250px;
  background-color: rgb(234, 234, 234);
}
.menu-modal.dark {
  background-color: var(--bg-item-dark);
}

/* ----- Beat Hive ----- */
.hive-cluster {
  display: grid;
  grid-template-columns: repeat(auto-fit, 39px);
  height: 230px;
  width: 245px;
  border: 2px solid white;

  margin-inline: auto;
  grid-auto-flow: dense;
}
.hive-item {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  width: 78px;
  cursor: default;
  background-color: white;
  border-radius: 50%;
  font-size: 15px;
}
/* .hive-item:hover { */
/* position: absolute; */
/* width: 90px; */
/* } */
/* .hive-item-not {
  width: 80px;
} */

.hive-item-ctn:first-child {
  grid-column-start: 2;
  grid-column-end: 4;
}
.hive-item-ctn:nth-child(6) {
  grid-column-start: 2;
  grid-column-end: 4;
}
.hive-item-ctn:nth-child(7),
.hive-item-ctn:nth-child(3),
.hive-item-ctn:nth-child(4),
.hive-item-ctn:nth-child(5),
.hive-item-ctn:nth-child(6) {
  margin-top: -24px;
}

.span-2 {
  grid-column: span 2;
}
.span-3 {
  grid-column: span 3;
}

/*----- Pages -----*/
.lib-container {
  display: flex;
  justify-content: space-between;
}
.create-container,
.lib-container,
.feed-container,
.settings-container {
  padding-left: var(--padding);
  padding-right: var(--padding);
}

.feed-container {
  margin-top: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
